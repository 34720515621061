/* Position and sizing of burger button */
.bm-burger-button {
  display: flex;
  position: absolute;
  width: 30px;
  height: 22px;
  right: 26px;
  top: 25px;
}

@media only screen and (min-width: 992px) {
 .bm-burger-button {
   display: none;
 }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: var(--text-dark);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #ffffff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  /* border-bottom: 2px solid var(--text-light); */
  color: var(--text-dark);
  font-size: 16px;
  text-decoration: none;
  margin-top: 13px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
